import { Xid } from 'xid-ts';
import time from '@flyer/utils/time';
import { Dayjs } from 'dayjs';
import { CefrLevel } from '@/__generated__/graphql';

export const optionsV2 = { context: { v2: true } };
export function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}

export const dataPart = [
  {
    label: 'Part 1',
    value: 1,
  },
  {
    label: 'Part 2',
    value: 2,
  },
  {
    label: 'Part 3',
    value: 3,
  },
  {
    label: 'Part 4',
    value: 4,
  },
  {
    label: 'Part 5',
    value: 5,
  },
  {
    label: 'Part 6',
    value: 6,
  },
  {
    label: 'Part 7',
    value: 7,
  },
  {
    label: 'Part 8',
    value: 8,
  },
  {
    label: 'Part 9',
    value: 9,
  },
  {
    label: 'Part 10',
    value: 10,
  },
  {
    label: 'Part 11',
    value: 11,
  },
  {
    label: 'Part 12',
    value: 12,
  },
  {
    label: 'Part 13',
    value: 13,
  },
  {
    label: 'Part 14',
    value: 14,
  },
  {
    label: 'Part 15',
    value: 15,
  },
  {
    label: 'Part 16',
    value: 16,
  },
  {
    label: 'Part 17',
    value: 17,
  },
  {
    label: 'Part 18',
    value: 18,
  },
  {
    label: 'Part 19',
    value: 19,
  },
  {
    label: 'Part 20',
    value: 20,
  },
];

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};

export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const newXid = () => {
  return new Xid().toString();
};

export const convertTimeToStringDuration = (expiredTime: Dayjs) => {
  return time
    .duration({
      seconds: expiredTime.second(),
      hours: expiredTime.hour(),
      minutes: expiredTime.minute(),
    })
    .toISOString();
};

export const levelsData = [
  {
    label: 'PRE A1',
    value: CefrLevel.Prea1,
  },
  {
    label: 'A1',
    value: CefrLevel.A1,
  },
  {
    label: 'A2',
    value: CefrLevel.A2,
  },
  {
    label: 'B1',
    value: CefrLevel.B1,
  },
  {
    label: 'B2',
    value: CefrLevel.B2,
  },
  {
    label: 'C1',
    value: CefrLevel.C1,
  },
  {
    label: 'C2',
    value: CefrLevel.C2,
  },
];

export const colorOption = {
  [CefrLevel.Prea1]: { color: '#47C199', value: CefrLevel.Prea1 },
  [CefrLevel.A1]: { color: '#F48221', value: CefrLevel.A1 },
  [CefrLevel.A2]: { color: '#F95F3C', value: CefrLevel.A2 },
  [CefrLevel.B1]: { color: '#33CF4B', value: CefrLevel.B1 },
  [CefrLevel.B2]: { color: '#4C70E8', value: CefrLevel.B2 },
  [CefrLevel.C1]: { color: '#4FBAAD', value: CefrLevel.C1 },
  [CefrLevel.C2]: { color: '#AD2CCC', value: CefrLevel.C2 },
};
