import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics as firebaseGetAnalytics, Analytics } from 'firebase/analytics';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  signInWithRedirect,
  UserCredential,
  signOut,
} from 'firebase/auth';

import { isServer } from '../utils';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
const config = process.env.NEXT_PUBLIC_FIREBASE_CONFIG;

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
let app: FirebaseApp = initializeApp(JSON.parse(config));

export const auth = getAuth(app);

export function getAnalytics(configParam: object): Analytics | undefined {
  if (isServer()) {
    return undefined;
  }

  if (configParam) {
    app = initializeApp(configParam);
  }

  return firebaseGetAnalytics(app);
}

export const loginWithCustomToken = (token: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call

  if (!app) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    app = initializeApp(JSON.parse(config));
  }

  return signInWithCustomToken(auth, token);
};

export const loginWithGoogle = (withRedirect?: boolean): Promise<UserCredential> => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  if (withRedirect) {
    return signInWithRedirect(auth, provider);
  }
  return signInWithPopup(auth, provider);
};

export const logout = (): Promise<void> => {
  return signOut(auth);
};
