import env from '@beam-australia/react-env';

export const isServer = (): boolean => typeof window === 'undefined';

export const isLocalhost = (): boolean => window.location.host.startsWith('localhost');

export const getExamUrl = (): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const overrideExamUrl = env('OVERRIDE_EXAM_URL');
  if (overrideExamUrl) {
    return overrideExamUrl;
  }

  const parts = window.location.hostname.split('-');
  if (parts.length > 1) {
    const [appEnv, prodHostName] = parts;
    if (appEnv && prodHostName) {
      const rootHostName = prodHostName.split('.').slice(1).join('.');
      return `${window.location.protocol}//${appEnv}-exam.${rootHostName}`;
    }
  }

  const hostParts = parts[0]?.split('.');

  if (hostParts && hostParts.length < 2 && parts[0]) {
    return `${window.location.protocol}//${parts[0]}`;
  }

  if (hostParts) {
    return `${window.location.protocol}//exam.${hostParts?.slice(1).join('.')}`;
  }

  return '';
};
